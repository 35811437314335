.style-complects {
    &__info {
        width:800px;
        margin:0 auto;
        max-width:100%;
        padding:65px 0 0;
        font-size:24px;
        
        @include b(desktop){
            font-size:18px;
          }
    }
    &__complect {
        display:flex;
        flex-flow: row nowrap;
        align-items:stretch;
        justify-content:stretch;
        padding: 65px 0;
        @include b(tablet){
            flex-wrap:wrap;
        }
        .total {
            flex: 0 0 40%;
            flex-basis: calc(40% - 15px);
            padding: 0 15px 0 0;
            display:flex;
            flex-flow:column nowrap;
            align-items:center;
            justify-content:flex-start;
            @include b(tablet){
                padding: 0 0 45px 0;
                flex-basis:100%;
            }
            .complect {
                &__heading {
                 font-size:36px;   
                 padding:0 0 30px;
                 width:100%;
                 @include b(desktop){
                   font-size:24px
                 }
                 @include b(mobile){
                   font-size:28px
                 }
                }
                &__desc {
                    font-family:$sansFont;
                    font-size:19px;
                    font-weight:300;
                    color:#444;
                    line-heighT:normal;
                    padding:0 0 50px;
                    max-width:100%;
                    @include b(desktop){
                      font-size:15px
                    }
                }
                &__items {
                    img {
                        max-height:470px;
                        margin:0 auto;
                        @include b(desktop){
                            max-height:350px;
                        }
                    }
                }
            }
            
            &__footer{
                margin:auto auto 0;
                display:flex;
                flex-flow:row nowrap;
                align-items:center;
                justify-content:space-between;
                width:100%;
                padding: 50px 30px 0 0;
                @include b(tablet){
                    padding: 50px 0px 0;
                }
            }
            &__price {
                font-size:36px;
                color:#5f24e8;
                @include b(desktop){
                  font-size:24px
                }
            }
            &__action {
                flex: 0  0 130px;
                .check-box--ship {
                    width:100%;
                }
            }
        }
        .details {
            flex: 0 0 60%;
            flex-basis: calc(60% - 15px);
            padding: 0 0 0 15px;
            display:flex;
            flex-flow:column nowrap;
            align-items:center;
            justify-content:flex-start;
            border-left:1px solid #bdbdbd;
            @include b(tablet){
                padding: 0 0 30px 0;
                border-bottom: 1px solid #aeaeae;
                border-left:0;
                flex-basis:100%;
            }
            .complect {
                &__heading {
                 font-size:36px;   
                 padding:0 0 30px;
                 width:100%;
                 @include b(desktop){
                   font-size:24px
                 }
                 @include b(mobile){
                   font-size:28px
                 }
                }
                &__items {
                    display:flex;
                    flex-flow: row wrap;
                    align-items:flex-end;
                    justify-content:flex-start;
                    width:100%;
                    &> .item {
                        flex: 0 0 33.33%;
                        flex-basis: calc(33.33% - 30px);
                        padding:30px 15px;
                        display:flex;
                        flex-flow:column nowrap;
                        align-items:center;
                        @include b(mobile){
                            flex: 0 0 100%;
                            flex-basis: calc(100% - 30px);
                        }
                        .item {
                                
                            &__title {
                                font-size:24px;
                                text-align:center;
                                line-height:normal;
                                @include b(desktop){
                                  font-size:18px
                                }
                            }
                            &__image {
                                padding: 0 0 30px;
                                img {
                                    max-height:130px;
                                    margin:0 auto;
                                    @include b(desktop){
                                        max-height:100px;
                                    }
                                }
                                @include b(desktop){
                                    padding: 0 0 15px;
                                }
                            }
                            &__price {
                                color:#5f24e8;
                                font-size:36px;
                                text-align:center;
                                padding: 15px 0 0;
                                @include b(desktop){
                                  font-size:24px
                                }
                            }
                            &__check {
                                padding:15px 15px 0;
                                width:130px;
                                max-width:100%;
                            }
                        }
                    }
                }
            }
            
            &__footer{
                margin:auto auto 0;
                display:flex;
                flex-flow:row nowrap;
                align-items:center;
                justify-content:stretch;
                padding: 50px 30px 0;
                width:100%;
                @include b(desktop){
                    padding: 30px 0px 0;
                }
            }
            &__price {
                font-size:36px;
                color:#5f24e8;
                @include b(desktop){
                  font-size:24px
                }
            }
        }
    }
    &__action {
        border-top:1px solid #c1c1c1;
        padding: 90px 0 80px;
        [class*="btn"]{
            width:220px;
            margin:0 auto;
            max-width:100%;
        }
        @include b(tablet){
            border-top: 0;
            padding: 0 0 65px;
        }
    }
}
