.check-box--ship,
.radio-box--ship {
    input[type="checkbox"],
    input[type="radio"] {
        &+label {
            display: block;
            line-height: normal;
            position: relative;
            color: #786fce;
            text-align: center;
            font-size: 14px;
            height: 25px;
            line-height: 25px;
            padding: 0 15px;
            &:before {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                content: "";
                border-radius: 50px;
                position: absolute;
                z-index: -1;
                margin: 0;
                border: 0;
            }
            &:hover {
                color: #fff;
                &:before {
                    background: rgba(#786fce, .7);
                }
            }
        }
        &:checked+label {
            color: #fff;
            &:before {
                background: #786fce;
            }
        }
        &:focus+label:before {
            box-shadow: 0 0 0 2px #786fce !important;
        }
    }
}

.radio-box,
.check-box {
    &--button {
        [type="radio"],
        [type="checkbox"] {
            & + label {
                vertical-align: middle;
                text-align: center;
                position: relative;
                cursor: pointer;
                outline: 0;
                -webkit-appearance: none;
                line-height: 1.2em;
                border: 0;
                border-radius: 50px;
                padding: 11px 30px;
                position: relative;
                display: block;
                width: 100%;
                font-family: $mainFont;
                font-size: 24px;
                font-weight: 400;
                transition: all .15s ease-in-out;
                text-decoration: none;
                color: $themeColor;
                background-color: transparent;
                @include b(desktop){
                    font-size: 18px;
                    padding: 6px 15px;
                }
                @include b(mobile) {
                    font-size: 19px
                }
                &:before {
                    position: absolute;
                    display: block;
                    content: "";
                    width: calc(100% + 8px);
                    height: calc(100% + 8px);
                    border: 2px solid $themeColor;
                    left: -4px;
                    top: -4px;
                    border-radius: 50px;
                    transition: all .15s ease-in-out;
                    background:transparent;
                }
            }
            &:checked+label {

                background-color:$themeColor;
                color:#fff;
                &:before {
                    background-color: transparent;
                    background-image: none;
                }
            }
        }
    }
}