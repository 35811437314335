.home__board {
    padding: 150px 0;
    @include b(desktop) {
        padding: 110px 0;
    }
    @include b(min){
        padding:35px 0;
    }
}

.about__information {
    margin-top: 50px;
    margin-bottom: 25px;
}

.about__information .text__section {
    margin-bottom: 100px;
    @include b(desktop) {
        margin-bottom: 35px;
    }
}

.about__information .text__section .text__header {
    margin-top: 15px;
    margin-bottom: 15px;
}

.about__information .text__section p {
    font-family: 'Muli', sans-serif;
    margin-bottom: 10px;
}

.about__team__section {
    background-color: #E0DCE8;
    padding-bottom: 50px;
}

.about__team__section .header {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
}

.howitworks__section__features,
.about__team__section__people {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0 -15px;
    @include b(1150) {
        justify-content: space-around;
    }
}

.img__wrap {
    padding: 10px;
    width: 250px;
    margin:0 15px;
    @include b(desktop){
        width:calc(25% - 30px)
    }
    @include b(tablet){
        width:calc(33.33% - 30px)
    }
    @include b(mobile){
        width:calc(50% - 30px)
    }
    @include b(min){
        width:100%;
        margin-right:0!important;
        margin-left:0!important;
    }
}

.img__description {
    margin-top: 25px;
    text-align: center;
    position: relative;
}

.img__description .title {
    font-size: 1.5em;
    
    @include b(desktop){
        font-size:22px
    }
    @include b(mobile){
        text-align:center;
        display:block;
        &:after {
            margin:10px auto;
        }
    }
}

.about__team__section .img__description .title:hover {
    text-decoration: underline;
}

.img__description .sub__title {
    color: #444444;
}

.btn.login-btn {
    margin: 0;
    font-family: "Bellefair-Regular", "Times New Roman", serif;
    padding: 4px 10px;
}

.btn.login-btn:before {
    border: 1px solid #000;
}

.btn.login-btn:hover {
    color: #6138c2;
}

.btn.login-btn:hover:before {
    border-color: #6138c2;
}

.btn.social-btn:before {
    border-color: #5f24e8;
}

.login__container {
    margin-top: 70px;
    @include b(desktop) {
        margin-top: 25px;
    }
}

.separator {
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #444444;
    line-height: 0.1em;
    margin: 35px auto !important;    
    @include b(desktop){
        margin: 20px auto !important;    
    }
}

.separator span {
    color: #444444;
    font-family: 'Muli', sans-serif;
    background: #fff;
    padding: 0 20px;
}

.faq__board {
    padding: 200px 0;
    background-position:center top;
    @include b(desktop) {
        padding: 100px 0;
    }
    @include b(tablet) {
        padding: 75px 0;
    }
    @include b(mobile) {
        padding: 50px 0;
    }
}

.faq__container {
    margin-top: 50px;
    margin-bottom: 25px;
}

.faq__question__section {
    margin-bottom: 75px;
    width: 83%;
    @include b(desktop){
        margin-bottom: 3em;
    }
}

.faq__question__section h2 {
    font-family: "Bellefair-Regular", "Times New Roman", serif;
    margin-top: 15px;
    margin-bottom: 40px;
    @include b(desktop){
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.faq__question__section p {
    font-family: 'Muli', sans-serif;
    margin-bottom: 10px;
    color: #444444;
}

a.app__store__link {
    border-bottom: 1px solid #6138c2;
    color: #6138c2;
    cursor: pointer;
    margin-top: 10px;
}

.login__board,
.contact-us__board,.about__board {
    padding: 200px 0;
    background-position:center top;
    @include b(desktop) {
        padding: 100px 0;
    }
    @include b(tablet) {
        padding: 75px 0;
    }
    @include b(mobile) {
        padding: 50px 0;
    }
}

form.profile .input-group.prefix {
    margin-top: 20px !important;
    @include b(tablet) {
        margin-top: 0px;
    }
}

.input-group.prefix {
    margin: 15px 0 !important;
    position: relative;
    .input-box {
        margin: 0 !important;
    }
}

.input-group.prefix .icon {
    position: absolute;
    left: 0;
    top:50%;
    transform:translateY(-50%);
    z-index:2;
    img {
        width: 22px;
        max-width: 100%;
        @include b(desktop){
            width:16px;
        }
    }
}

.input-group.prefix input,
.input-group.prefix label {
    padding-left: 40px;
    @include b(desktop){
        padding-left: 25px;
    }
}

.main__form__container {
    width: 400px;
    max-width: 100%;
    padding-bottom: 25px;
    position: relative;
    margin:0 auto;
}

.forgotpassword__form__container {
    width: 400px;
    padding-top: 40px;
    padding-bottom: 180px;
    @include b(desktop) {
        padding-top: 15px;
        padding-bottom: 50px;
    }
}

label.contact__us__textarea__label {
    top: 50%;
    left: 0;
    color: #444;
    font-size: 24px;
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    line-height: 50px;
    white-space: nowrap;
    z-index: -1;
    @include b(desktop){
        font-size:18px;    
        line-height: 35px;
    }
}

textarea.contact__us__textarea {
    min-height: 170px;
    resize: none;
    color: #000;
    font-size: 24px;
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    @include b(desktop){
        font-size:18px;    
        line-height: normal;
    }
}

.login__actions {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    font-size: 20px;
    font-family: 'Muli', sans-serif;
    margin: 0 -15px;
    @include b(desktop){
        font-size:13px;
    }
}

.login__actions a {
    cursor: pointer;
    color: #5f24e8;
    flex: 1 1 auto;
    margin: 25px 15px 0;
    &:last-child {
        text-align: right;
    }
    @include b(desktop){
        margin: 15px 15px 0;
    }
    @include b(400) {
        text-align: center !important;
    }
}

.login__actions a:hover {
    text-decoration: underline;
}

.forgotpassword__title {
    text-align: center;
    color: #444444;
    font-size: 24px;
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    margin-top: 75px;
    @include b(desktop) {
        margin-top: 25px;
        font-size: 18px;
    }
}


.static_login {
    padding: 50px 0;
    @include b(mobile) {
        padding: 25px 15px;
    }
}

@media (max-width: 767px) {
    form.profile .input-group.prefix {
        flex-flow: unset;
    }
    label.contact__us__textarea__label {
        font-size: 19px;
    }
}

.text-header {
    font-size: 36px;
    padding: 0 0 10px;
}

.static__text {
    font-family: $sansFont;
    color: #666;
    b {
        font-family: $sansFont;
        color: #000;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &,
        b,
        strong {
            font-family: $mainFont;
            margin: 3em 0 1em;
            font-weight: 300;
            font-variant: normal;
            font-style: normal;
            text-transform: capitalize;
            color: #000;
            font-weight: normal;
            @include b(desktop){
                margin: 1.5em 0 .75em;
            }
        }
    }
    p,
    ul,
    ol {
        margin: 0 0 1em 0;
    }
    ul {
        list-style: none;
        li {
            padding: 0;
            margin: 0 0 1em 0;
            color: #000;
            &:before {
                content: none;
            }
        }
    }
}