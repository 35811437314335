.tabs.preferences {
    margin: 60px 0;
    .tabs__nav {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        background: #eee;
        margin: 0 -30px;
        .tab__link {
            flex: 0 0 auto;
            font-size: 26px;
            margin: 0 30px;
            &--active {
                color: #6138c2;
            }
        }
        @include b(mobile) {
            flex-flow: column wrap;
            margin: 0;
            padding: 15px 0;
            .tab__link {
                margin: 15px 0;
            }
        }
    }
    .tabs__conts {
        padding: 30px 0 0;
    }
}

form.profile {
    &+& {
        margin-top: 60px;
    }
    .page-title {
        padding: 0 0 30px;
    }
    .input-group {
        margin: -15px;
        @include b(mobile) {
            margin: 0;
        }
        .input-box {
            width:calc(50% - 30px);
            margin: 15px;
            display:inline-block;
            &.input-group {
                .input-box {
                    vertical-align:bottom;
                    &:first-of-type {
                        margin-left: 0;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                    @include b(mobile) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }
        @include b(mobile) {
            flex-flow: column nowrap;
            .input-box {
                flex: 1 1 100%;
                width: 100%;
                &+.input-box {
                    margin: 30px 0 0;
                }
            }
        }
    }
    .profile__action {
        margin: 60px auto 0;
        [class*="btn"] {
            display: inline-block;
            width: auto;
        }
    }
}