.payment {
    position:relative;
    z-index:0;
    padding: 0 0 75px;
    .input-group {
        display:flex;
        flex-flow: row wrap;
        align-items:flex-start;
        justify-content:space-between;
        margin: -15px -15px 15px;
        z-index:1;
        position:relative;
        &:last-child {
            margin-bottom:0;
        }
        .input-box {
            flex: 1 1 50%;
            flex-basis: calc(50% - 30px);
            margin: 15px 15px 0;
            @include b(tablet){
                flex: 0 0 100%;
                flex-basis: calc(100% - 30px);
            }
        }
    }
    .form-block {
        padding:20px 30px 40px;
        margin: 50px auto 0;
        width:615px;
        max-width:100%;
        &--gray {
            background:#f3f1f7;
            border-radius: 15px;
        }
        &-heading {
            font-size: 50px;
            margin: 0 0 30px;
            @include b(mobile){
              font-size:40px
            }
        }
    }
    &__action {
        margin: 10px  auto 0;
        width:215px;
        max-width:100%;
    }
}