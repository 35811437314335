.questions {
    .question {
        margin: 75px 0 0;
        text-align: center;
        &__heading {
            margin: 0;
            padding: 0 0 35px;
            position: relative;
            .info {
                display: inline-block;
                width: 17px;
                height: 17px;
                vertical-align: baseline;
                margin-left: 15px;
            }
        }
        &__radios,&__checks {
            margin: 0 auto;
            display: inline-flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            margin: -40px -30px 0;
            max-width:100%;
            &--images, &--photos {
                margin: -40px -65px 0;
                align-items:flex-start;
                justify-content: center !important;
                &--s {
                    margin: -40px -15px 0;
                }
            }
            @include b(tablet) {
                margin: -20px -15px 0;
                width:auto !important;
                display:block;
                &--images, &--photos {
                    margin: -20px -30px 0;
                    display:block; 
                }
            }
            @include b(mobile) {
                margin-left:0;
                margin-right:0;
                &--images, &--photos {
                    margin: -20px 0 0;
                    flex-flow: column wrap !important;
                    align-items:center;
                }
            }
            .radio-box, .check-box {
                flex: 0 0 125px;
                flex-grow:0;
                flex-shrink: 0;
                flex-basis: 125px;
                margin: 40px 30px 0;
                &--image, &--photo {
                    flex: 0 0 auto;
                    margin: 40px 65px 0;
                    justify-content:center;
                    &--s {
                        flex: 0 0 calc(25% - 70px) !important;
                        flex-grow: 0;
                        flex-shrink: 0;
                        flex-basis: calc(25% - 70px) !important;
                        margin: 40px 35px 0 !important;
                    }
                }
                &--nowrap {
                    white-space:nowrap;
                }
                @include b(desktop) {
                    margin: 20px 30px 0;
                    display:inline-block;
                    &--image, &--photo {
                        flex-grow: 0 !important;
                        flex-shrink: 0 !important;
                        margin: 40px 30px 0;
                        display:inline-block;
                        img {
                            height:auto;
                            max-height:250px;
                            @include b(desktop) {
                                max-height:150px;
                            }
                        }
                        &--s {
                            margin: 40px 30px 0 !important;
                        }
                    }
                }
            }
            &--center {
                justify-content: center;
            }
            &--nowrap {
                flex-wrap: nowrap;
                justify-content: center;
                &--wide {
                    @include b(desktop) {
                        justify-content: flex-start;
                    }
                    @include b(mobile) {
                        justify-content: center;
                    }
                }
                @include b(desktop) {
                    flex-wrap: wrap;
                }
            }
            &--wide {
                width: calc(100% + 60px);
                @include b(tablet) {
                    width: calc(100% + 30px);
                }
                @include b(mobile) {
                    width: 100%;
                }
            }
            &--narrow {
                width: calc(100% - 60px);
                @include b(tablet) {
                    width: 100%;
                }
            }
            &--slim {
                width: calc(100% - 300px);
                @include b(tablet) {
                    width: 100%;
                }
            }
            &--4 {
                .radio-box, .check-box {               
                    flex-basis:calc(25% - 60px);
                }
            }
            &--2 {
                min-width:50%;
                max-width: 100%;
                .radio-box, .check-box {                    
                    flex-basis: calc(50% - 60px);
                }
            }
            &--3 {
                .radio-box, .check-box {                    
                    flex-basis: calc(33.33% - 60px);
                }
            }
        }
        .input-group {
            width: 555px;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: stretch;
            margin: 0 auto;
            &.select-group{
                justify-content:center;
            }
            .input-box {
                flex: 1 0 auto;                
                &.select-box {
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    flex: 0 1 auto;
                    margin:0;
                    &+.input-box {
                        margin-left: 50px;
                    }
                    label {
                        white-space:normal;
                        margin-left:25px;
                        font-size:18px;
                        @include b(desktop){
                            font-size:15px;
                        }
                    }
                }
            }
            @include b(mobile) {
                flex-flow: column nowrap;
                .input-box {
                    flex: 1 0 auto;
                    &+.input-box {
                        margin-left: auto;
                        margin-top: 30px;
                    }
                    &.select-box {
                        flex: 1 0 100%;
                        width:100%;
                        &+.input-box {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
        
        .color-box {
            margin: 50px 25px 0;
            @include b(desktop) {
                margin: 35px 20px 0;
            }
            @include b(mobile) {
                margin: 15px 15px 0;
            }
        }
        &__colors {
            margin: -50px auto 0;
            padding: 0 145px;
            &--6 {
                padding: 0 60px;
            }
            @include b(tablet) {
                padding: 0 50px;
            }
            @include b(mobile) {
                padding: 0;
                margin: -15px -15px 0;
            }
        }
        &__upload {
            border: 1px solid #bbb1d3;
            border-radius: 20px;
            padding: 25px;
            width:750px;
            max-width:100%;
            margin:0 auto;
            .upload__photos {
                min-height: 120px;
                background: url(../img/misc/upload.png) center no-repeat;
                background-size: auto 50%;
                display:flex;
                flex-flow: row wrap;
                align-items:center;
                justify-content:center;
                padding:0 0 25px;
                margin: -15px -15px 0;
                img {
                    opacity:0;
                    visibility:0;
                    transition: all .15s ease-in-out;
                    height:120px;
                    width:auto;
                    flex:0 0 auto;
                    display:block;
                    margin: 15px 15px 0;
                }
                &.uploaded {
                    background-size:auto 0%; 
                    img {
                        opacity:1;
                        visibility:1;
                    }
                }
            }
            .hint {
                font-family:$sansFont;
                font-size:14px;
                font-weight: 300;
                color:#444;
                padding:15px 0 0;
                display:block;                    
            }
            label {
                width: 130px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
    &__action {
        padding: 60px 0;
        margin: -15px;
        text-align:center;
        [class*="btn"]{
            display:inline-block;
            margin:15px;
            width:auto;
        }
    }
}