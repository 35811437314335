select {
	display: block;
	@include clearSelect;
	transition: none;
	background-position: right 25px center;
	background-repeat: no-repeat;
	background-image: url('../img/sprite/retina/2x/select-down.png');
	background-size: 29px 17px;
	background-color: #fff;
	&:focus {
		background-image: url('../img/sprite/retina/2x/select-up.png');
	}
}
