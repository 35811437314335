@font-face {
  font-family: 'Bellefair';
  src: url('../fonts/Bellefair/Bellefair-Regular.eot');
  src: url('../fonts/Bellefair/Bellefair-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bellefair/Bellefair-Regular.woff2') format('woff2'),
      url('../fonts/Bellefair/Bellefair-Regular.woff') format('woff'),
      url('../fonts/Bellefair/Bellefair-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dancing Script';
  src: url('../fonts/DancingScript/DancingScript-Regular.eot');
  src: url('../fonts/DancingScript/DancingScript-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DancingScript/DancingScript-Regular.woff2') format('woff2'),
      url('../fonts/DancingScript/DancingScript-Regular.woff') format('woff'),
      url('../fonts/DancingScript/DancingScript-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dancing Script';
  src: url('../fonts/DancingScript/DancingScript-Bold.eot');
  src: url('../fonts/DancingScript/DancingScript-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DancingScript/DancingScript-Bold.woff2') format('woff2'),
      url('../fonts/DancingScript/DancingScript-Bold.woff') format('woff'),
      url('../fonts/DancingScript/DancingScript-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-ExtraLightItalic.eot');
  src: url('../fonts/Muli/Muli-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-ExtraLightItalic.woff') format('woff'),
      url('../fonts/Muli/Muli-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-LightItalic.eot');
  src: url('../fonts/Muli/Muli-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-LightItalic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-LightItalic.woff') format('woff'),
      url('../fonts/Muli/Muli-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-Black.eot');
  src: url('../fonts/Muli/Muli-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-Black.woff2') format('woff2'),
      url('../fonts/Muli/Muli-Black.woff') format('woff'),
      url('../fonts/Muli/Muli-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-Bold.eot');
  src: url('../fonts/Muli/Muli-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-Bold.woff2') format('woff2'),
      url('../fonts/Muli/Muli-Bold.woff') format('woff'),
      url('../fonts/Muli/Muli-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-ExtraBoldItalic.eot');
  src: url('../fonts/Muli/Muli-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-ExtraBoldItalic.woff') format('woff'),
      url('../fonts/Muli/Muli-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-SemiBoldItalic.eot');
  src: url('../fonts/Muli/Muli-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-SemiBoldItalic.woff') format('woff'),
      url('../fonts/Muli/Muli-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-SemiBold.eot');
  src: url('../fonts/Muli/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-SemiBold.woff2') format('woff2'),
      url('../fonts/Muli/Muli-SemiBold.woff') format('woff'),
      url('../fonts/Muli/Muli-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-ExtraBold.eot');
  src: url('../fonts/Muli/Muli-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-ExtraBold.woff2') format('woff2'),
      url('../fonts/Muli/Muli-ExtraBold.woff') format('woff'),
      url('../fonts/Muli/Muli-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-Light.eot');
  src: url('../fonts/Muli/Muli-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-Light.woff2') format('woff2'),
      url('../fonts/Muli/Muli-Light.woff') format('woff'),
      url('../fonts/Muli/Muli-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-Italic.eot');
  src: url('../fonts/Muli/Muli-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-Italic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-Italic.woff') format('woff'),
      url('../fonts/Muli/Muli-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-ExtraLight.eot');
  src: url('../fonts/Muli/Muli-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-ExtraLight.woff2') format('woff2'),
      url('../fonts/Muli/Muli-ExtraLight.woff') format('woff'),
      url('../fonts/Muli/Muli-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-BoldItalic.eot');
  src: url('../fonts/Muli/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-BoldItalic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-BoldItalic.woff') format('woff'),
      url('../fonts/Muli/Muli-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-Regular.eot');
  src: url('../fonts/Muli/Muli-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-Regular.woff2') format('woff2'),
      url('../fonts/Muli/Muli-Regular.woff') format('woff'),
      url('../fonts/Muli/Muli-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli/Muli-BlackItalic.eot');
  src: url('../fonts/Muli/Muli-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Muli/Muli-BlackItalic.woff2') format('woff2'),
      url('../fonts/Muli/Muli-BlackItalic.woff') format('woff'),
      url('../fonts/Muli/Muli-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
