.breadcrumbs {
	padding:14px 0 0;
		@include b(767){
			display:none;
		}
	ul {
		font-size:0;
		li {
			padding:0;
			margin:0;
			display:inline-block;
			vertical-align:middle;
			a {
				font-size:13px;
				line-height:13px;
				color:#616161;
				text-decoration: none;
				position:relative;
				font-family:'ChantillyLH', sans-serif;
				&:hover {
					text-decoration:none;
					color:#fff;
				}
			}
			&:first-child {
				&:before {
					display:none;
				}
			}
			&:last-child {
				a {
					pointer-events:none;
				}
			}
			& + li {
				&:before {
				    display: inline-block;
				    content: ">";
				    font-size: 11px;
				    background: transparent;
				    width: auto;
				    height: auto;
				    border-radius: 0;
				    position: relative;
				    margin: 0 0 0 4px;
				    vertical-align: baseline;
				    color: #616161;
				    top: 0;
				}
			}
		}
	}
}