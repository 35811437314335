.cart {
  &__items {
    .item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: stretch;
      padding: 60px 0 40px;
      border-bottom: 1px solid #bdbdbd;
      @include b(mobile) {
        flex-flow: row wrap;
        padding: 30px 0 20px;
      }
      &:last-of-type {
        border: 0;
      }
      &__image {
        flex: 0 1 305px;
        padding: 15px 15px 15px 40px;
        img {
          max-width: 160px;
          margin:0 auto;
          @include b(desktop){
            max-width: 120px;
          }
        }
        @include b(desktop){
          flex: 0 1 30%;
          padding: 15px ;
        }
        @include b(mobile) {    
          padding: 15px 0px 15px 0px;
          flex-basis: 100%;
        }
        .fees {
          padding: 60px 0 0;
          .fee {
            text-align: center;
            font-size: 24px;
            padding: 15px 0 0;
            &-cost {
              color: #5f24e8;
            }
          }
        }
      }
      &__details {
        flex: 1 1 100%;
        padding: 0 40px 0 15px;
        flex-basis: calc(100% - 55px);
        margin-left: 30px;
        border-left: 1px solid #bdbdbd;
        @include b(desktop) {
          padding: 0 0px 0 15px;
        }
        @include b(mobile) {
          margin-left: 0;
          border-left: 0;
        }
      }
      &__heading {
        font-size: 36px;
        padding: 0 0 30px;
        @include b(desktop){
            font-size: 24px;
            padding: 0 0 15px;
        }
      }
      &__attributes {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        margin: 0 -15px;
        &-col {
          flex: 1 1 50%;
          flex-basis: calc(50% - 30px);
          margin: 0 15px 30px;
          @include b(mobile) {
            flex: 0 1 100%;
            flex-basis: calc(100% - 30px);
            margin: 0 15px 30px;
          }
        }
        &-group {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-start;
          padding: 15px 0;
          margin:-10px;
          .radio-box, .check-box {
            flex: 0 0 auto;
            margin: 10px;
          }
        }
      }
      &__comment {
        height: 100px;
      }
      &__manufacturer,&__size,&__color {
        font-family: $sansFont;
        font-size: 19px;
        font-weight: 300;
        @include b(desktop){
          font-size:15px;
        }
      }
      &__size,&__color {
        margin:15px 0;
        @include b(desktop){
          margin: 0;
        }
        .value {
          color:#5f24e8;
        }
      }
      &__desc {
        font-family: $sansFont;
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        padding: 15px 0 0;
        @include b(desktop){
          font-size:13px;
        }
      }
      &__footer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 45px 0 0;
        &--left {
          justify-content: flex-start;
        }
      }
      &__price {
        font-size: 36px;
        color: #5f24e8;
        @include b(desktop){
            font-size: 24px;
        }
      }
      .item__reason{
        display:none;
      }
      &__actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 -15px;
        .radio-box, .check-box {
          margin: 0 15px;
          flex: 1 1 auto;
        }
        @at-root .item__footer--left .item__actions {
          margin-left: 30px;
        }
      }
    }
  }
  &__total {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    padding: 30px 0;
		@include b(mobile) {
			
			justify-content: center;
		}
    &-col {
      padding: 0 30px;
      @include b(mobile){
        padding:0;
      }
      &.fees-col {
        flex:0 1 400px;
        max-width:100%;
      }
    }
    .fees {
      .fee {
        text-align: left;
        font-size: 24px;
        padding: 15px 0 0;
        position: relative;
        &-cost {
          color: #5f24e8;
          position: absolute;
          left: 100%;
          transform: translateX(-100%);
        }
      }
    }
    .promo {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      @include b(desktop){
        padding: 20px 0 10px;
      }
      input {
        flex: 0 0 230px;
        flex-basis: 200px;
        border: 1px solid #bdbdbd;
        border-radius: 15px;
        height: auto;
        padding: 8px 15px;
        font-size: 15px;
        margin-right: 30px;
        @include placeholder(#aaaaaa, 300, $sansFont);
      }
      @include b(mobile) {
        flex-flow: row wrap;
        justify-content: center;
        input {
          margin-right: 0;
          margin-bottom: 30px
        }
      }
    }
    .total-sum {
      width: 290px;
      max-width: 100%;
      padding: 30px 0;
      position: relative;
      font-size: 36px;
      @include b(desktop){
        padding: 15px 0;
        font-size:24px
      }
      .total-title {
        text-align: left;
      }
      .total-cost {
        color: #5f24e8;
        position: absolute;
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }
  &__action {
    padding: 60px 0 100px;
    [class*="btn"] {
      width: 250px;
      max-width: 100%;
      margin: 0 auto;
    }
    &.cart__total {
      justify-content: flex-start;
			@include b(mobile) {
				justify-content: center;
			}
      .total-sum {
        width: auto;
        margin: 0 30px 0 0;
        @include b(mobile) {
					margin-right: 0;
					width:100%;
					text-align:center;
        }
      }
      .total-cost {
        position: relative;
        left: auto;
        transform: none;
      }
      [class*="btn"] {
        width: auto;
        max-width: 100%;
        margin: 0 30px;
        @include b(mobile) {
          margin: 0 15px;
        }
        &:last-of-type {
          margin-right: 0;
        }
        @include b(mobile) {
					margin-right: 0;
					margin-bottom:30px;
					width:100%;
					&:last-of-type {
						margin-bottom: 0;
					}
        }
      }
    }
  }
}