.tabs.profile {
    position:relative;
    margin: 60px 0;
    @include clearfix;
    @include b(tablet){
        flex-flow:column wrap;
        align-items:stretch;
        justify-content:flex-start;
    }
    .tabs__nav {
        display:flex;
        flex-flow:column wrap;
        align-items:center;
        justify-content:flex-start;
        padding: 0 30px 0 0; 
        box-shadow: 1px 0 0 0 #bdbdbd; 
        float:left;
        width:250px;
        @include b(tablet){
            float:none;
            width:100%;
            flex-flow: row wrap;
            padding: 0 0 30px;
            box-shadow:none;
        }
        .tab__link {
            flex: 0 0 100%;
            margin: 0 0 30px;
            font-size:26px;
            &:last-of-type {
                margin: 0;
            }
            @include b(desktop){
                margin: 0 0 15px;
                font-size:18px;
            }
            @include b(mobile){
                margin: 0 0 15px;
                font-size:19px;
                line-height:normal;
            }
            &--active {
                color:#6138c2;
            }
        }
    }
    .tabs__conts {
        float:right;
        width:calc(100% - 250px);
        padding: 0 0 0 60px;
        border-left:1px solid #bdbdbd;
        @include b(tablet){
            border-left:0;
            padding:30px 0 0;
            border-top:1px solid #bdbdbd;
            float:none;
            width:100%;
        }
    }
}

form.profile {
    & + & {
        margin-top: 60px;
    }
    .page-title {
        padding:0 0 30px;
    }
    .input-group {
        display: flex;
        flex-flow:row wrap;        
        align-items: center;
        justify-content: stretch;
        margin: -15px;
        @include b(mobile){
            margin: 0;
        }
        .input-box {
            flex: 1 1 50%;
            flex-basis: calc(50% - 30px);
            margin: 15px;
            &.input-group {
                .input-box {
                    &:first-of-type {
                        margin-left: 0; 
                    }  
                    &:last-of-type {
                        margin-right: 0; 
                    }
                    @include b(mobile){
                            margin-left: 0;
                            margin-right: 0;
                    }
                }
            }
        }
        @include b(mobile) {
            .input-box {
                flex: 1 1 100%;
                width:100%;
                &+.input-box {
                    margin: 30px 0 0;
                }
            }
        }

    }
    .profile__action {
        margin: 60px auto 0;
        [class*="btn"]{
            display:inline-block;
            width:auto;
        }
    }
}