

	textarea,
	select,
	.input,
	input[type="text"] ,
	input[type="email"] ,
	input[type="url"] ,
	input[type="number"] ,
	input[type="password"],
	input[type="tel"]  {
	    display: block;
	    width: 100%;
	    height: 50px;
	    padding: 0;
	    background: transparent;
	    color: #000;
	    font-size: 24px;
	    font-family: $sansFont;
	    font-weight: 300;
	    border: 0;
	    border-bottom: 1px solid #aeaeae;
	    outline: 0;
		-webkit-appearane: none;
		border-radius:0;
	    transition: all .15s ease-in-out;
	    @include placeholder(#444444, 300, $sansFont);
		@include b(desktop){
			font-size:15px;    
			height:20px;
		}
        @include b(mobile){
          font-size:19px
        }
	    &:focus,
	    &:hover {
			border-color: #000;
			outline:0;
	    }
	}

	label {
	    font-size: 13px;
	    line-height: 20px;
	    font-family: 'Engravers Gothic', sans-serif;
	}

	textarea {
		height: 150px;
		padding:11px 0;
		resize:vertical;
		white-space:normal;
		&.rounded {
			border:1px solid #aeaeae;
			border-radius:15px;
			padding:18px;
			font-size:15px;
			line-height:normal;
			font-family: $sansFont;
			@include placeholder(#aaa)
		}
		@at-root .validate &:invalid {
			border-color:#e40000;
		}
	}

	input[type="file"] {
	    display: none;
	    &+label {
			@extend .btn--theme--transparent;
			font-size:14px;
			font-family:$sansFont;
			font-weight:300;
			padding:5px 30px;
			&:before {
				content:"";
				width: calc(100% + 4px);
				height: calc(100% + 4px);
				border-width: 1px;
				left: -2px;
				top: -2px;
			}
	    }
	}

	.input-box {
	    max-width: 100%;
	    margin: 0 auto;
		position: relative;
		& + & {
			margin-left:30px;
		}
	    input, textarea, select {
	        z-index: 0;
	        &+label {
	            position: absolute;
	            top: 50%;
	            transform: translateY(-50%);
	            left: 0;
	            color: #444;
	            font-size: 24px;
	            font-family: $sansFont;
	            font-weight: 300;
	            line-height: 50px;
	            white-space: nowrap;
	            transition: all .15s ease-in-out;
	            z-index: -1;
				@include b(desktop){
					font-size:15px;    
					line-height: 35px;
				}
	        }
	        &:focus,
	        &:valid {
	            &+label {
	                top: -1.2em;
	                transform: translateY(0);
	                font-size: 12px;
					color: #000;
					line-height:1em;
	            }
	        }
			@at-root .validate &:invalid {
				border-color:#e40000;
	            &+label {
	                color: #e40000;
	            }
	        }
	    }
	}


	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	input:-webkit-autofill:valid,
	input:-webkit-autofill:invalid {		
		background-color:transparent !important;
		box-shadow: 0 0 0 500px #fff inset;
		transition: background-color 0s ease-in-out 60s;
	}