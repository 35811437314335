//----------------------------------------------------//
// Variables                                          //
//----------------------------------------------------//
$mainFont:			'Bellefair','Times New Roman', serif; 
$mainFontSize:		18px;
$mainFontHeight:	24px;
$mainFontWeight:	normal;
$mainFontColor:		#000;
$sansFont:          'Muli', sans-serif;
$cursiveFont:       'Dancing Script';

// Variables => Colors
//----------------------------------------------------//
$default:$mainFontColor;
$themeColor: #5f24e8;
