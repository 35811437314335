.steps {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    padding: 0 100px;
    @include b(tablet){
        padding:0;
    }
    .step {
        position: relative;
        text-align: center;
        flex: 1 1 auto;
        color: #9a88c3;
        border-top: 1px solid #9a88c3;
        padding: 8px 0;
        transition:all .15s ease-in-out;
        font-size: 24px;
        cursor:pointer;
        @include b(desktop){
            font-size: 18px;
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            background: #9a88c3;
            transition:all .15s ease-in-out;
        }
        &:before {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: -2px;
            left: 50%;
            transform: translateX(-50%);
        }
        &:after {
            top: -1px;
            width: 50%;
            height: 1px;
            left: 0;
        }
        &.active,
        &.complete{
            color: #000;
            &:before {
                width: 8px;
                height: 8px;
                top: -4px;
                background: #000;
                border: 1px solid #fff;
                box-shadow: 0 0 0 1px #000;
            }
            &:after {
                background: #000;
            }
        }
        &.complete {
            border-color: #000;
        }
        &:hover,
        &:active {
            color: #000;
            &:before {
                width: 8px;
                height: 8px;
                top: -4px;
                background: #000;
                border: 1px solid #fff;
                box-shadow: 0 0 0 1px #000;
            }
        }
    }
}