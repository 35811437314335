.history__items {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: center;
    min-width:250px;
    .item {
        flex: 0 0 25%;
        -ms-flex-preferred-size: calc(25% - 30px);
        padding: 30px 15px;
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: center;
        @include b(tablet) {
            flex: 0 0 50%;
            flex-basis:calc(50% - 30px)
        }
        @include b(mobile) {
            flex: 0 0 100%;
            flex-basis:calc(100% - 30px)
        }
        &__title {
            font-size: 24px;
            text-align: center;
            line-height: normal;
            max-width:100%;
            @include b(desktop) {
                font-size: 18px;
            }
        }
        &__image {
            padding: 0 0 30px;
            max-width:100%;
            img {
                max-height: 130px;
                margin: 0 auto;
            }
        }
        &__price {
            color: #5f24e8;
            font-size: 36px;
            text-align: center;
            padding: 15px 0 0;
            @include b(desktop) {
                font-size: 28px;
            }
        }
        &__check {
            padding: 15px 15px 0;
            width: 130px;
            max-width: 100%;
        }
    }
}

.history {
    padding: 15px 0;
    margin: 30px 0;
}

.history-table {
    margin: 0;
    th {
        text-align: center !important;
        &:first-child {
            border-right: 0;
        }
    }
    td {
        font-family: $sansFont;
        font-size: 14px;
        border-bottom: 1px solid #cacaca;
    }
    tr:last-child td {
        border-bottom: 0;
    }
}

.pager {
    padding: 30px 0;
    margin:0 auto 70px;
    text-align:center;
    &__link {
        font-size: 14px;
        line-height: 25px;
        height: 25px;
        width: 25px;
        text-align: center;
        display: inline-block;
        border: 1px solid transparent !important;
        border-radius:50%;
        &.active {
            border-color: #7e75d1 !important;
            color:#5f24e8;
        }
        &--prev, &--next {
            width:auto;
            margin: 0 10px;
        }
    }
}