//----------------------------------------------------//
// LAYOUT                                             //
//----------------------------------------------------//
*,
*:before,
*:after {
	box-sizing: border-box;
	outline:0;
}
html {
	overflow-x:hidden;
}
body {
	position:relative;
	min-width: 320px;
	overflow-x:hidden;
	background:#fff;
}
.noscroll {
	overflow:hidden !important;
	height:100%;
	position:relative;
}
.page {
	display:flex;
	flex-flow:column nowrap;
	width:100%;
}
.container {
	padding: 0 30px;
	width: 1170px;
	max-width:100%;
	margin: 0 auto; 
	position:relative;
	@include b(desktop){
		padding: 0 30px;
	}
	@include b(tablet){
		padding: 0 20px;
	}
	@include b(mobile){
		padding: 0 15px;
	}
}


.hidden {
	display:none !important;
}
.visible {
	display:block !important;
}
.desktop-hidden {
	@include b(desktop) {
		display:none !important;
	}
}
.desktop-visible {
	@include b(desktop) {
		display:block !important;
	}
}
.tablet-hidden {
	@include b(tablet) {
		display:none !important;
	}
}
.tablet-visible {
	@include b(tablet) {
		display:block !important;
	}
}
.mobile-hidden {
	@include b(mobile) {
		display:none !important;
	}
}
.mobile-visible {
	@include b(mobile) {
		display:block !important;
	}
}
.min-hidden {
	@include b(min) {
		display:none !important;
	}
}
.min-visible {
	@include b(min) {
		display:block !important;
	}
}

.j-pp-mockup {
	position:absolute;
	top:0;
	margin-left:50%;
	transform:translateX(-50%);
}