
  .home__title {
    text-align: center;
    padding: 45px 0 0;
    margin: 0;
    font-size: 70px;
    line-height: 1em;
    color: #fff;
    text-transform: uppercase;
    @include b(desktop){
        font-size: 50px;        
    }
  }
  
  .home__separator{
    width:33%; 
    text-align:center; 
    border-bottom: 1px solid #fff; 
    line-height:0.1em; 
    margin-left: 33%;
    margin-top:5px;
    margin-bottom:15px;
    display: block;
  }
  
  .home__sub__title{
    text-align: center;
    font-size: 24px;
    line-height:normal;
    font-family: "Muli",serif;
    color: #fff;
    @include b(desktop){
        font-size: 17px;        
    }
  }
  
  .howitworks__section,
  .customer__reviews__section{
    background-color: #fff;
    padding-bottom:50px;
  }
  
  
  .howitworks__section .img__wrap{
    margin: 0 15px;
    z-index: 1;
  }
  .howitworks__section .img__wrap .img__description .background__image{
    background-image: url(../img/home/1_bg.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 110px;
    position: absolute;
    top: -25px;
    right: -15px;
    padding-left: 30px;
    padding-right: 70px;
    z-index:-1;
    @include b(desktop){
        height:90px
    }
  }
  
  .howitworks__section .img__wrap:nth-child(1) .img__description .background__image{
    background-image: url(../img/home/1_bg.png);
  }
  .howitworks__section .img__wrap:nth-child(2) .img__description .background__image{
    background-image: url(../img/home/2_bg.png);
  }
  
  .howitworks__section .img__wrap:nth-child(3) .img__description .background__image{
    background-image: url(../img/home/3_bg.png);
  }
  
  .howitworks__section .img__wrap:nth-child(4) .img__description .background__image{
    background-image: url(../img/home/4_bg.png);
  } 
  
  .howitworks__section .img__wrap .img__description .title{
    padding:13px;
  }
  
  .home__header{
    margin-top:50px;
    margin-bottom:50px;
    text-align: center;
    font-size: 60px;
    @include b(desktop){
        font-size: 33px;   
        margin-top:25px;
        margin-bottom:17px;     
    }
  }
  .howitworks__section .img__description .sub_title{
    font-size:17px;
    font-family: "Muli",sans-serif;
    @include b(desktop){
        font-size:13px
    }
  }
  
  .howitworks__section .img__description .title::after{
    content: "";
    width:33%; 
    text-align:center; 
    border-bottom: 1px solid #000;
    line-height:0.1em; 
    margin-left: 33%;
    margin-top:10px;
    margin-bottom:10px;
    display: block;
  }
  
  .customer__reviews__section .img__description .title::after{
    content: "";
    width:33%; 
    text-align:right; 
    border-bottom: 1px solid #000;
    line-height:0.1em; 
    margin-top:10px;
    margin-bottom:10px;
    display: block;
  }
  
  a.faq__more__link{
    font-family: "Bellefair", "Times New Roman", serif;
    margin-top:30px;
    text-align: center;
    color:#5f24e8;
    display:block;
    cursor: pointer;
    text-decoration: underline;
  }
  
  a.faq__more__link:hover{
    color:#5f24e8;
    text-decoration: none;
  }
  
  .faq__block{
    position: relative;
    padding-top:20px;
    padding-bottom:20px;
    margin:30px 0px;
    background-color:#f3f1f7;
  }
  
  .faq__desc{
    padding:10px;
    background-color: #f3f1f7;
    text-align: center;
    font-size: 17px;
    font-family: "Muli",sans-serif;
    display: none;
    @include b(desktop){
        font-size:13px
    }
  }
  
  .faq__desc.open{
    display: block;
  }
  
  label.faq__item {
    background-color: #f3f1f7;
    font-family: "Bellefair", "Times New Roman", serif;
    font-size: 1.5em;
    padding: 2px 5px;
    display:block;
    text-align: center;
    @include b(desktop){
        font-size:22px
    }
  }
  
  
  .toggle{
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    z-index: 1;
    background-size:cover;
    display:block;
    cursor: pointer;
  }
  
  .toggle.toggle-plus{
    background-image: url(../img/home/plus.png);
  }
  
  .toggle.toggle-minus{
    background-image: url(../img/home/minus.png);
  }
  
  /*styles for media*/
  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    @include b(mobile){
      display:block;
    }
  }
  
  .media-body {
    flex: 1;
  }
  
  .mt-0, .my-0 {
    margin-top: 0!important;
  }
  
  .customer__reviews__section .img__description{
    text-align: left;
    margin-top: 0px;
    padding:20px;
    font-size: 19px;
    font-family:'Muli';
    font-weight:300;
    
    @include b(desktop){
      font-size: 15px;
  }
  }
  .customer__reviews__box {
    display:flex;
    flex-flow: row wrap;
    align-items:flex-start;
    justify-content:stretch;
    margin:0 -15px;
    .instagram__link {
      position:relative;
      top: 20px;
    }
  }
  .media.img__wrap{
    flex: 0 0 50%;
    flex-basis:calc(50% - 30px);
    margin-top:20px;
    @include b(mobile){
      flex: 0 0 100%;
        .media__image {
            margin:0 auto;
        }
    }
  }
  
  .media__image{
    width: 130px; 
    height: 130px;
  }
  
  .customer__reviews__section .img__description::before{
    content:"";
    background-repeat: no-repeat;
    background-position: left;
    background-image: url(../img/home/quote.png);
    background-size:cover;
    position: absolute;
    width: 100px;
    height: 80px;
    top: 0px;
    left: 0px
  }
  .customer__reviews__section .img__description::after{
    content:"";
    background-repeat: no-repeat;
    background-position: right;
    background-image: url(../img/home/quote.png);
    background-size:cover;
    position: absolute;
    bottom:0px;
    right:0px;
    width: 100px;
    height: 80px;
    /* Safari */
  -webkit-transform: rotate(-180deg);
  
  /* Firefox */
  -moz-transform: rotate(-180deg);
  
  /* IE */
  -ms-transform: rotate(-180deg);
  
  /* Opera */
  -o-transform: rotate(-180deg);
  
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  }
  
  .customer__reviews__section .img__description{
    min-height:180px;
  }
  
  .img__text__block{
    position: relative;
    z-index: 2;
  }

  .img__wrap .circle {
    width:140px;
    margin:0 auto;
    @include b(desktop){
      width:140px;  
    }
  }


  .login_form {
    .input-group {
      margin: 15px 0 !important;
      position:relative;
      max-width:100%;
    }
      .input-box {
      margin:  0 !important;
    }
  }