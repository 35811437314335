.footer {
    position: relative;
    height: auto;
    padding: 35px 0 25px;
    background:#f3f1f7;
    &__bottom {
        text-align: center;
        border-top:1px solid #fff;
        padding:20px 0 0;
    }
    small {
        font-size:9px;
        color:#c6badf;
    }
    &__logo {
        position: relative;
        img {
            margin: 0 auto;
            width:144px;
        }
    }
    &__nav {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        padding:0 0 25px;
        &-col {
            flex: 0 0 auto;
            &--wide {
                flex-basis: 240px;
                width:240px;
            }
        }
        @include b(tablet){
            display:block;
            &-col {
                flex:1 0 100%;
                width:100%;
                text-align:center;
                & + & {
                    margin-top:15px;
                }
            }
        }
    }
    ul {
        text-align: center;
        margin: 0;
        padding: 0;
        li {
            padding: 0;
            margin: 0;
            &:before {
                display: none;
            }
            a {
                color: #7e949b;
                font-size:17px;
				@include b(desktop){
					font-size:15px;    
				}
                &:hover {
                    color: #000;
                }
            }
        }
    }
    h4 {
        font-size: 18px;
        text-align: center;
        line-height: 1em;
        white-space: nowrap;
        padding: 0 0 24px;
        margin: 0;        
        @include b(desktop){
            padding: 0 0 10px;
        }
    }
    .social-links {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        @include b(tablet){
            width:240px;
            max-width:100%;
            margin: 0 auto 15px;
        }
        a {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 34px;
            height: 34px;
            transition: none;
            svg {
                max-width:100%;
                max-height:100%;
                display:block;
                fill:#c6badf;
                transition:fill .15s ease-in-out;
            }
            &:hover, &:focus {
                svg {
                    fill:#000;
                }
            }
        }
    }
    .app-links {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        @include b(tablet){
            width:240px;
            max-width:100%;
            margin: 15px auto 0;
        }
        a {
            flex: 0 0 50%;
            transition:opacity .15s ease-in-out;
            width:50%;
            img, svg {
                max-width: 100%;
                display: block;
                margin: 0 auto;
                height: 35px;
            }
            &:hover, &:focus {
                opacity:.8;
            }
        }
    }
}