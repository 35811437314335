button,
.btn {
    vertical-align: middle;
    text-align: center;
    color: #000;
    position: relative;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.2em;
    background-color: #fff;
    border:0;
    border-radius: 50px;
    padding: 8px 15px;
    position: relative;
    display: block;
    width: 100%;
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 400;
    transition: all .15s ease-in-out;
    text-decoration: none;
    @include b(desktop){
        font-size:18px
    }
    @include b(mobile){
      font-size:19px
    }
    &:before {
        position: absolute;
        display: block;
        content: "";
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        border: 2px solid #fff;
        left: -4px;
        top: -4px;
        border-radius: 50px;
        transition: all .15s ease-in-out;
    }
    &:hover,&:focus {
        text-decoration: none;
        &:before {
            border-color: $themeColor;
        }
    }
    &--primary {
        @extend .btn;
    }
    &--transparent {
        @extend .btn;
        color: #fff;
        background-color: transparent;
    }
    &--theme {
        @extend .btn;
        background-color: $themeColor;
        color: #fff;
        &:before {
            border-color: $themeColor;
        }
        &:hover,&:focus {
            &:before {
                border-color: transparent;
            }
        }
    }
    &--theme--transparent {
        @extend .btn--theme;
        color: $themeColor;
        background-color: transparent;
    }
    &--theme--inverse {
        @extend .btn--theme--transparent;
        &:hover,&:focus {
            background-color:$themeColor;
            color:#fff;
            &:before {
                border-color: $themeColor;
            }
        }
    }
    i,
    span {
        display: inline-block;
        vertical-align: middle;
    }
    i {
        margin-right: 13px;
        padding-right: 13px;
        border-right: 1px solid;
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            height: 23px;
        }
    }
    &--close {
        width: 15px;
        height: 15px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        border: 0;
        box-shadow: 0;
        transition: all .15s ease-in-out;
        ;
        background: url(../img/misc/close_popup_icon.png) center no-repeat;
        background-size: contain;
        opacity: .5;
        &:hover,&:focus {
            opacity: 1
        }
    }
}
.social-btn {
    @include b(mobile){
        padding-right:15px;
        padding-left:15px;
    }
}