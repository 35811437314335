
.subscription-options {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 60px 0 100px;
  position:relative;
  width:auto;
	@include b(desktop){
    margin: 30px 0 50px;
	}
  &__option {
    display: block;
    flex: 0 0 33.33%;
    flex-basis: calc(33.33% - 30px);
    margin: 0;
    min-height: 540px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    @include b(desktop){
      min-height: 435px;
    }
    .option-content {
      padding: 25px;
      background: rgba(#000, .5);
      color: #fff;
      position: absolute;
      top: 100%;
      transform: translateY(-135px);
      transition: all .15s ease-in-out;
      @include b(desktop){
        transform: translateY(-109px);
      }
    }
    .option-title {
      @extend .popup__heading;
      line-height: .9em;
      &:before {
        background: #fff;
      }
    }
    .option-desc {
      font-family: $sansFont;
      font-size:19px;
      font-weight:300;
      @include b(desktop){
        font-size:15px;
      }
    }
    &:hover,&:focus {
      .option-content {
        transform: translateY(-100%);
      }
    }
  }
  @include b(mobile){
      display:block;
      margin: 20px 0 40px;
      &__option {
          margin: 0 auto;
          min-height:0;
          .option-content {
              top:0;
              transform:none;
              position:relative;
          }
          &:hover,&:focus {
            .option-content {
                transform:none;
            }
          }
      }
  }
}